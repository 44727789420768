import React, { Fragment } from "react"
import { graphql } from "gatsby"
import { BlogBySlugQuery } from "../generated/graphql"
import idx from "idx"

const BlogTemplate = ({ data }: { data: BlogBySlugQuery }) => {
  const html = idx(data, (_) => _.markdownRemark.html) || ""
  const title = idx(data, (_) => _.markdownRemark.frontmatter.title) || ""
  const displayDate =
    idx(data, (_) => _.markdownRemark.frontmatter.displayDate) || ""

  return (
    <Fragment>
      <h1>{title}</h1>
      <h3 className="text-center">- {displayDate} -</h3>
      <div dangerouslySetInnerHTML={{ __html: html }} />
    </Fragment>
  )
}

export const query = graphql`
  query BlogBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        subtitle
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        title
        displayDate: date(formatString: "MMMM YYYY")
      }
    }
  }
`

export default BlogTemplate
